// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";


.print-disp-only-printing {
    display: none;
  }
@media print {
    .print-disp-only-printing {
      display: block;
    }
    .print-page-break {
      page-break-after: always;
    }
    
    .print-responsive-table-adujst {
      width: 100% !important;
      table-layout: fixed;
      overflow: hidden !important;
      margin: 0px !important;
    }
    
    .print-table-adujst {
      width: 100% !important;
      table-layout: fixed;
      overflow: hidden !important;
      margin: 0px !important;
    }

    .print-container-adujst {
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      overflow: hidden !important;
    }
    
    .print-col-size-adujst {
      width: 10% !important;
      min-width: 80px !important;
      max-width: 140px !important;
    }
    
    .print-dynamic-col-size-adujst {
      min-width: 20px !important;
    }


  }